import * as echarts from "echarts";
const handred = 100;
let point = 66;
const pieData = () => {
  // console.log("echarts", echarts);
  return {
    title: {
      text: point + "%",
      x: "center",
      y: "center",
      textStyle: {
        fontWeight: "normal",
        color: "#29EEF3",
        fontSize: "30",
      },
    },
    graphic: [
      {
        type: "text",
        z: 150,
        left: "center",
        top: "90%",
        style: {
          fill: "#fff",
          text: "生产进度",
          // text: [
          //     '横轴表示温度，单位是°C',
          //     '纵轴表示高度，单位是km',
          //     '右上角有一个图片做的水印',
          //     '这个文本块可以放在图中各',
          //     '种位置'
          // ].join('\n'),
          font: "18px Microsoft YaHei",
        },
      },
    ],
    // tooltip: {
    //   formatter: function (params: any) {
    //     return params.name + "：" + params.percent + " %";
    //   },
    // },
    legend: {
      show: true,
      itemGap: 12,
    },

    series: [
      {
        name: "circle",
        type: "pie",
        clockWise: true,
        radius: ["50%", "66%"],
        itemStyle: {
          normal: {
            label: {
              show: false,
            },
            labelLine: {
              show: false,
            },
          },
        },
        hoverAnimation: false,
        data: [
          {
            value: point,
            itemStyle: {
              normal: {
                color: {
                  // 颜色渐变
                  colorStops: [
                    {
                      offset: 0,
                      color: "#4FADFD", // 0% 处的颜色
                    },
                    {
                      offset: 1,
                      color: "#28E8FA", // 100% 处的颜色1
                    },
                  ],
                },
                label: {
                  show: false,
                },
                labelLine: {
                  show: false,
                },
              },
            },
          },
          {
            value: handred - point,

            itemStyle: {
              normal: {
                color: "#E1E8EE",
              },
            },
          },
        ],
      },
    ],
  };
};

// 排名列表
var array1 = [
  "台站1",
  "台站2",
  "科技大学",
  "台站4",
  "台站5",
  "台站6",
  "台站7",
  "台站8",
  "台站9",
  "台站10",
  "台站11",
  "台站12",
];
var array = array1.reverse();
const rankingList = () => {
  return {
    // backgroundColor: "rgba(2,13,34,1)",
    title: {
      text: "台站执行任务数量排名",
      textStyle: {
        fontSize: 16,
        fontWeight: 200,
        color: "#ffffff",
      },
    },
    tooltip: {
      show: true,
      trigger: "axis",
      axisPointer: {
        type: "line",
      },
    },
    grid: {
      top: "20",
      bottom: "10",
      left: "20",
      containLabel: true,
    },
    xAxis: [
      {
        show: false,
      },
    ],
    yAxis: {
      type: "category",
      data: array,
      axisLabel: {
        color: "rgba(255,255,255,1)",
        formatter: function (value: any) {
          return value;
        },
      },
      axisTick: {
        show: false,
      },
      axisLine: {
        show: false,
      },
      zlevel: 99,
    },
    series: [
      {
        type: "bar",
        barWidth: "30%",
        barCategoryGap: "20",
        itemStyle: {
          normal: {
            barBorderRadius: [10, 10, 10, 10],
            color: new echarts.graphic.LinearGradient(0, 0, 1, 0, [
              {
                offset: 0,
                color: "#048BFE",
              },
              {
                offset: 1,
                color: "#00EEF1",
              },
            ]),
            label: {
              show: true,
              position: "right",
              textStyle: {
                color: "#fff",
                fontSize: "14",
              },
            },
          },
        },
        data: [23, 34, 45, 48, 55, 59, 64, 74, 82, 84, 86, 90],
      },
    ],
  };
};

const schedule = () => {
  let myColor = [
    "#eb2100",
    "#eb3600",
    "#d0570e",
    "#d0a00e",
    "#34da62",
    "#00e9db",
    "#00c0e9",
    "#0096f3",
    "#33CCFF",
    "#33FFCC",
  ];
  return {
    // backgroundColor: "#0e2147",
    grid: {
      left: "2%",
      top: "12%",
      right: "10%",
      bottom: "8%",
      containLabel: true,
    },
    xAxis: [
      {
        show: false,
      },
    ],
    yAxis: [
      {
        axisTick: "none",
        axisLine: "none",
        offset: "2",
        axisLabel: {
          textStyle: {
            color: "#ffffff",
            fontSize: "12",
          },
        },
        data: [
          "M1",
          "M2",
          "M3",
          "M4",
          "M5",
          "M6",
          "M7",
          "M8",
          "M9",
          "M10",
          "M11",
        ],
      },
      {
        axisTick: "none",
        axisLine: "none",
        axisLabel: {
          textStyle: {
            color: "#ffffff",
            fontSize: "13",
          },
          formatter: function (p: any) {
            return p + "%";
          },
        },
        data: ["4", "13", "25", "29", "38", "44", "50", "52", "60", "72", "50"],
      },
      {
        nameTextStyle: {
          color: "#ffffff",
          fontSize: "12",
        },
        axisLine: {
          lineStyle: {
            color: "rgba(0,0,0,0)",
          },
        },
        data: [],
      },
    ],
    series: [
      {
        name: "条",
        type: "bar",
        yAxisIndex: 0,
        data: [4, 13, 25, 29, 38, 44, 50, 52, 60, 72, 50],
        label: {
          normal: {
            show: false,
            position: "right",
            textStyle: {
              color: "#ffffff",
              fontSize: "12",
            },
          },
        },
        barWidth: 14,
        itemStyle: {
          normal: {
            color: function (params: any) {
              let num = myColor.length;

              return myColor[params.dataIndex % num];
            },
          },
        },
        z: 2,
        animation: true,
        animationEasing: "cubicOut",
      },
      {
        type: "scatter",
        yAxisIndex: 0,
        symbol: "rect",
        symbolSize: [2, 4],
        data: [4, 13, 25, 29, 38, 44, 50, 52, 60, 72, 50],
        itemStyle: {
          normal: {
            borderColor: "#E2F2FF",
            color: "#E2F2FF",
          },
        },
        z: 0,
      },
      {
        name: "外框",
        type: "bar",
        yAxisIndex: 2,
        // barGap: "-100%",
        data: [100, 100, 100, 100, 100, 100, 100, 100, 100, 100, 100],
        barWidth: 16,
        itemStyle: {
          normal: {
            color: "rgba(255,255,255,0.2)",
          },
        },
        z: 0,
      },
    ],
  };
};

export { pieData, rankingList, schedule };
